<template>
  <v-app id="inspire">
       <v-app-bar color="blue" app  dark>
            <v-toolbar-title class="white--text"><strong>TIMAUKEL</strong></v-toolbar-title>
         <v-spacer></v-spacer>
          <v-btn icon @click="dialog=true">
            <v-icon>
            mdi-cancel
            </v-icon>
          </v-btn>

    
    </v-app-bar>

    <v-main class="grey lighten-3" >
      <v-container>
       <slot name="contenido"></slot>

        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
             ¿Cerrar Sesion?
            </v-card-title>


            <v-card-actions>

              <v-btn
                color="red darken-1"
                text
                @click="dialog = false"
              >
                No
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="closeSession()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data(){
      return {
        dialog:false
      }
    },
   methods:{
     closeSession(){
       this.dialog=false;
         window.localStorage.removeItem("token");
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("userName");
          this.$router.push("/");
     }
   }
  }
</script>