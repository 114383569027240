export const validateRut = (rut, dv) => {
  /* eslint-disable */
  let fullRut = rut + "-" + dv;
  fullRut = fullRut.replace("‐", "-");
  console.log(fullRut);
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(fullRut)) return false;
  /* eslint-enable */
  // var tmp     = rutCompleto.split('-');
  var digv = dv;
  if (digv == "K") digv = "k";

  return validateDv(rut) == digv;
};

function validateDv(T) {
  var M = 0,
    S = 1;
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : "k";
}

export const isNumber = (evt) => {
  const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const keyPressed = evt.key;
  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault();
  }
};
