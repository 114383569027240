<template>
  <div>
   <PlantillaLogin>
    <div slot="contenido">
     <v-row>
          <v-col
            cols="12"
            sm="2"
          >
           
          </v-col>

          <v-col
            cols="12"
            sm="8"
          >
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
                  <h1 style="text-align: center;">Ingreso</h1> 
                    <v-form action   @submit.prevent="conectar"
                        ref="form"
                        
                        lazy-validation
                        >
                        <v-text-field
                        v-model="user.email"
                        label="Correo Electronico"
                        required
                        
                        ></v-text-field>
                        
                        <v-text-field 
                        type="password"
                        v-model="user.password"
                        label="Contraseña"
                        required>
                        
                        </v-text-field>

                    
                        <v-btn color="success" type="submit"  style="text-align: center;">
                        Conectar
                        </v-btn>

                    
                    </v-form>
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            sm="2"
          >
          
          </v-col>
        </v-row>
     
    </div>
   </PlantillaLogin>
  </div>
</template>

<script>
import PlantillaLogin from '../template/PlantillaLogin.vue'
import { getRolesByEmail } from "@/services/role";
import { getSablishmentsByRole } from "@/services/stablishment";
export default {
  name: 'Login',
  components: {
    PlantillaLogin: PlantillaLogin
  },
     data() {
        return {
        user: {
            email: "",
            password: "",
            grant_type: "password",
            token: "",
            role_id: 9,
            stablishment_id: 4
        },
        error: "",
        user_role_id: null,
        
        isLoading: false,
        roles: [],
        stablishments: []
        };
    },
    methods: {
    async chargeRoles() {
      this.areRolesLoading = true;
      this.areStablishmentsLoading = true;
      this.stablishments = [];
      this.roles = await getRolesByEmail(this.$http, this.user.email);
      if (this.roles.length !== 0) {
        this.user.role_id = this.roles[0].role_id;
        this.user_role_id = this.roles[0].id;
        this.areRolesLoading = false;
        this.stablishments = await getSablishmentsByRole(this.$http, this.roles[0].id);
        if (this.stablishments.length !== 0) {
          this.user.stablishment_id = this.stablishments[0].stablishment_id;
        } else {
          this.user.stablishment_id = null;
        }
      } else {
        this.user.role_id = null;
      }
      this.areRolesLoading = false;
      this.areStablishmentsLoading = false;
    },
    async chargeStablishments() {
      this.areStablishmentsLoading = true;
      let user_role_id = this.roles.find(role => role.role_id == this.user.role_id).id;
      this.stablishments = await getSablishmentsByRole(this.$http, user_role_id);
      if (this.stablishments.length !== 0) {
        this.user.stablishment_id = this.stablishments[0].stablishment_id;
      } else {
        this.user.stablishment_id = null;
      }
      this.areStablishmentsLoading = false;
    },
    conectar: function () {
      this.error = "";
      this.isLoading = true;
      this.$http
        .post("api/auth", {
          email: this.user.email,
          password: this.user.password,
          grant_type: this.user.grant_type,
          role_id: this.user.role_id,
          stablishment_id: this.user.stablishment_id
        })
        .then(async (response) => {
          console.log(response.body.user);
          this.user.token = response.body.token;
          window.localStorage.setItem(
            "token",
            (this.user.token = response.body.token)
          );
          window.localStorage.setItem(
            "userName",
            `${response.body.user.name} ${response.body.user.apepat} ${response.body.user.apemat}`
          );
          window.localStorage.setItem("stablishment", this.user.stablishment_id);
          window.localStorage.setItem("perfil_id", this.user.role_id);
          
          window.localStorage.setItem("perfil",'consult_medic');
          window.localStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
          );
          // this.user.role_id = response.body.user.role_id;
          switch (this.user.role_id) {
            case 1:
              console.log("administrator")
              this.$router.push({ name: "administratorHome" });
              this.isLoading = false;
              break;
            case 2:
              console.log("administrative")
              this.$router.push({ name: "administrativeHome" });
              this.isLoading = false;
              break;
            case 3:
              this.$router.push({ name: "supervisorHome" });
              this.isLoading = false;
              break;
            case 4:
              this.$router.push({ name: "collectorHome" });
              this.isLoading = false;
              break;
            case 5:
              this.$router.push({ name: "laboratoryHome" });
              this.isLoading = false;
              break;
            case 6:
              this.$router.push({ name: "notificatorHome" });
              this.isLoading = false;
              break;
            case 7:
              this.$router.push({ name: "managerHome" });
              this.isLoading = false;
              break;
            case 8:
              this.$router.push({ name: "assistantHome" });
              this.isLoading = false;
              break;
            case 9:
              this.$router.push({ name: "consultMedicHome" });
              this.isLoading = false;
              break;
            case 11:
              this.$router.push({ name: "consultantHome" });
              this.isLoading = false;
              break;
            case 12:
              this.$router.push({ name: "tracerHome" });
              this.isLoading = false;
              break;
            case 13:
              this.$router.push({ name: "executiveHome" });
              this.isLoading = false;
              break;
          }
          // if (this.user.role_id === 1) {
          //   this.$router.push(
          //     {
          //       name: "login",
          //     },
          //     () => {}
          //   );
          //   this.isLoading = false;
          // } else if (this.user.role_id === 2 ||this.user.role_id === 5 || this.user.role_id === 3 || this.user.role_id === 4) {
          //   this.$router.push({ name: "administrative"});
          //   this.isLoading = false;
          // }
        })
        .catch((response) => {
          this.isLoading = false;
          this.error = "Usuario y/o contraseña incorrecta";
          console.error(response);
        });
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
