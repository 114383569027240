export const getResult = async (resource, data) => {
    let result;
    await resource.get(`api/${localStorage.getItem('perfil')}/result/${data}`).then(
        (response) => {
            // success callback
            result = response.body.result[0];
        },
        (response) => {
            console.log(response);
            // error callback
        }
    );
    return result;
}