import ConsultMedic from '@/views/consult_medic/Home.vue'
import EmptyRouterView from '@/views/consult_medic/EmptyRouterView.vue'

export const routes = [
  { path: '/consultor-medico', 
    component: EmptyRouterView,
    children: [
      {
        path: '', 
        name: 'consultMedicHome',
        component: ConsultMedic,
      },
     
    ]
  }
]