export const getSamplesByRut = async (resource, rut) => {
    let samples;
    await resource.get(`api/${localStorage.getItem('perfil')}/sample/find_by_patient_rut/${rut}`).then(
      (response) => {
        // success callback
        console.log(response.body.sample);
        samples = response.body.sample;
      },
      (response) => {
        console.log(response);
        // error callback
      }
    );
    return samples;
  }
  
  export const getSamplesByPassport = async (resource, passport) => {
    let samples;
    await resource.get(`api/${localStorage.getItem('perfil')}/sample/find_by_patient_passport/${passport}`).then(
      (response) => {
        // success callback
        console.log(response.body.sample);
        samples = response.body.sample;
      },
      (response) => {
        console.log(response);
        // error callback
      }
    );
    return samples;
  }
  
  export const getSamplesByRN = async (resource, code_rn) => {
    let samples;
    await resource.get(`api/${localStorage.getItem('perfil')}/sample/find_by_patient_rn/${code_rn}`).then(
      (response) => {
        // success callback
        console.log(response.body.sample);
        samples = response.body.sample;
      },
      (response) => {
        console.log(response);
        // error callback
      }
    );
    return samples;
  }


  export const getSampleById = async (resource, id)=>{
    let samples;
    await resource.get(`api/${localStorage.getItem('perfil')}/sample/`+id).then(
      (response)=>{
        console.log(response.body.sample);
        samples = response.body.sample;
      },(response)=>{
        console.log(response);
      }
    );
    return samples;

  }