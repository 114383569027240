import Login from "./../views/Login.vue";
import { routes as consultRoutes } from "./consult_medic";

export const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "login",
    component: Login,
  },
  ...consultRoutes,
  {
    path:'*',
    redirect:'/'
  }
];
