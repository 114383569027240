<template>
  <v-app id="inspire">
       <v-app-bar color="blue" app>
        <v-row>
            <v-col class="d-flex justify-space-around" >
            <v-toolbar-title class="white--text"><strong>TIMAUKEL</strong></v-toolbar-title>
            </v-col>     
        </v-row>

    
    </v-app-bar>

    <v-main >
      <v-container>
       <slot name="contenido"></slot>

       
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      links: [
        'Dashboard',
        'Messages',
        'Profile',
        'Updates',
      ],
    }),
  }
</script>