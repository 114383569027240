<template>
  <div>
   <PlantillaPrincipal>
    <div slot="contenido">
       <dialogDetail v-if="dialogActivator" 
       :dialogActivator="dialogActivator"
       :desactivateDialog="desactivateDialog"
       :sample="sample" >
       </dialogDetail >
        <v-dialog
            v-model="dialogLoader"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                Please stand by
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        <v-card>
            <v-card-text>
                Ingrese los datos solicitados para realizar busqueda,
                recuerde que los datos aqui mostrados son confidenciales
            </v-card-text>
                <v-row>
                    <v-col cols="1">
              
                    </v-col>
                    <v-col cols="10" sm="1">
                        <v-select label="Documento" 
                        @change="changeDocumentType()"
                        :items="documentTypes" 
                        v-model="document_type_id"
                        item-text="name"
                        item-value="id"
                        selected="true" >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1">

                    </v-col>
                    <v-col cols="8" sm="2" v-if="document_type_id==1">
                        <v-text-field v-model="rut"  label="RUN" :error-messages="error">
                        </v-text-field>
                    </v-col>
                    
                    <v-col cols="2" sm="2" v-if="document_type_id==1">
                        <v-text-field v-model="dv" label="DV" :error-messages="error">
                        </v-text-field>
                    </v-col>

                    <v-col cols="10" sm="2" v-if="document_type_id==2">
                        <v-text-field v-model="passport" label="Passaporte">
                        </v-text-field>
                    </v-col>

                    <v-col cols="10" sm="2" v-if="document_type_id==4">
                        <v-text-field v-model="code_rn" label="Recien Nacido">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="5" >
                     <v-btn class="ma-2" block color="primary" @click="search()" >
                            Buscar
                        <v-icon dark right>
                            mdi-checkbox-marked-circle
                        </v-icon>
                     </v-btn>
                    </v-col>
                    <v-col cols="5">
                        <v-btn class="ma-2" block color="success" dark @click="clean()">
                            Limpiar      
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>

            <br>
            <v-card
              class="mx-auto"
              tile
              v-if="isCardVisible"
            >
              <v-list dense>
                <v-subheader>Reporte por fecha</v-subheader>
                <v-list-item-group
                  v-model="resultado_id"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in results"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon >mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.created_date" @click="activateDialog(item.id,item)"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
    </div>
   </PlantillaPrincipal>
  </div>
</template>
<script>
import {getSamplesByRut, getSamplesByPassport, getSamplesByRN} from "@/services/sample";
import {validateRut} from "@/services/utils";
import dialogDetail from "./dialogDetail.vue"
import PlantillaPrincipal from '../../template/PlantillaPrincipal.vue'

export default {
  name: 'Login',
  components: {
    PlantillaPrincipal: PlantillaPrincipal,
    dialogDetail
  },
     data() {
        return {
             documentTypes: [
        {
          id: 1,
          name: "Rut",
        },
        {
          id: 2,
          name: "Pasaporte",
        },
        {
          id: 4,
          name: "Recién nacido",
        },
      ],
      sample: [],
      resultado_id:null,
      document_type_id: 1,
      passport: "",
      code_rn: "",
      rut: "",
      dv: null,
      isRutValid: true,
      isCardVisible:false,
      results:[],
      dialogActivator:false,
      dialogLoader:false,
      error: null
        };
    },
    methods: {
         clean() {
            this.passport = "";
            this.code_rn = "";
            this.rut = "";
            this.dv = "";
            this.isRutValid = true;
            this.document_type_id=1;
            this.isCardVisible =false;

        },
        changeDocumentType() {
        this.rut = "";
        this.dv = "";
        this.passport = "";
        this.code_rn = "";
        },
    async search() {
      
      if (this.rut) {
        if (validateRut(this.rut, this.dv)) {
         let result = await getSamplesByRut(this.$http, this.rut);
         console.log(result)
         this.results = result;
         this.isCardVisible =true;
          this.error=null;
        } else {
          console.log('false')
          this.error="Error";
          this.clean()
          this.isRutValid = false;
        }
      } else if (this.passport) {
       let result = await getSamplesByPassport(this.$http, this.passport);
       console.log(result)
      } else {
       let result = await getSamplesByRN(this.$http, this.code_rn);
       console.log(result)
      }
    },
      async activateDialog(id, item){
        this.dialogLoader=true;
        this.sample = item;
       // let sample = await getSampleById(this.$http,id);
        console.log(this.sample)
        this.dialogLoader=false;
        this.dialogActivator=true;

      },
      desactivateDialog(){
        this.dialogActivator=false;
      }

    },

 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>