<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="desactivar()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detalle Muestra</v-toolbar-title>
          <v-spacer></v-spacer>
        
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
                    <v-subheader>DATOS DEL PACIENTE</v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Nombre del Paciente</v-list-item-title>
              <v-list-item-subtitle>{{this.sample.patient.names}} {{this.sample.patient.pather_last_name}} {{this.sample.patient.mother_last_name}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
         
          <v-list-item>
          <v-row>
            <v-col cols="4">
            <v-list-item-content>
              <v-list-item-title>Telefono</v-list-item-title>
              <v-list-item-subtitle>{{this.sample.patient.phone}}</v-list-item-subtitle>
            </v-list-item-content>

            </v-col>
            <v-col cols="8">
            <v-list-item-content>
              <v-list-item-title>Correo Electronico</v-list-item-title>
              <v-list-item-subtitle>{{this.sample.patient.email}}</v-list-item-subtitle>
            </v-list-item-content>

            </v-col>
          </v-row>
          </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <result-pdf v-if="this.sample.state == 'con resultado'"
                :id="this.sample.samples_movements[this.sample.samples_movements.length-1].results[0].id"></result-pdf>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Detalles Toma de Muestra</v-subheader>
            <v-row>
                <v-col cols="6">
          <v-list-item>
                <v-list-item-content>
              <v-list-item-title>Fecha de Muestra</v-list-item-title>
              <v-list-item-subtitle>{{this.sample.created_date}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
                </v-col>
                <v-col cols="6">
                    <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Establecimiento</v-list-item-title>
                    <v-list-item-subtitle>{{this.sample.stablishment.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                </v-col>
            </v-row>
        </v-list>
                <v-divider></v-divider>
            <v-list
                    three-line
                    subheader
                    >
          <v-subheader>Detalles Resultado</v-subheader>
            <v-row>
                <v-col cols="6">
          <v-list-item>
                <v-list-item-content>
              <v-list-item-title>Fecha de Resultado</v-list-item-title>
              <v-list-item-subtitle>{{this.sample.samples_movements.length !== 0 ? this.sample.samples_movements[this.sample.samples_movements.length - 1].results.length !== 0 ? this.sample.samples_movements[this.sample.samples_movements.length - 1].results[0].date_result : 'Sin resultado' : 'Sin resultado'}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
                </v-col>
                <v-col cols="6">
                    <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Resultado</v-list-item-title>
                    <v-list-item-subtitle>{{this.sample.samples_movements.length !== 0 ? this.sample.samples_movements[this.sample.samples_movements.length - 1].results.length !== 0 ? this.sample.samples_movements[this.sample.samples_movements.length - 1].results[0].result_type.name : 'Sin resultado' : 'Sin resultado' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                </v-col>

            </v-row>
        </v-list>
       
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import ResultPdf from '../../components/resultPdf';
  export default {
    props:['dialogActivator','desactivateDialog','sample'],
    components:{
        ResultPdf
    },
    data () {
      return {
        dialog: this.dialogActivator,
      }
    },
    methods:{
        desactivar(){
            this.dialog=false;
            this.desactivateDialog();
        }
    }
  }
</script>