import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'

import { routes } from './routes/routes.js'
import './registerServiceWorker'

Vue.use(VueRouter)
Vue.use(VueResource);

Vue.config.productionTip = false

const enrutador = new VueRouter({
  routes: routes,
  mode: "history",
  linkExactActiveClass: "is-active" // active class for *exact* links.
});

enrutador.beforeEach((to, from, next) => {
  let isAuthenticated = window.localStorage.getItem("token") || undefined;
  if (to.name !== "Login" && !isAuthenticated) {
    next({ name: "Login" });
  } else {
    console.log('next')
    next();
  }
});

Vue.http.options.root = "https://tomamuestra-api.dssm.cl";
Vue.http.interceptors.push((request, next) => {
  var token = window.localStorage.getItem("token") || undefined;
  if (token) {
    request.headers.set("Authorization", "Bearer" + token);
    request.headers.set("Accept", "application/json");
  } else {
    return;
  }
  next((response) => {
    if (response.status === 0) {
      // enrutador.push("/");
    } else if (response.status === 500) {
      //  enrutador.push('/')
    } else if (response.status === 401) {
    //  eventBus.$emit("value", true);
    }
  });
});

new Vue({
  vuetify,
  router: enrutador,
  render: h => h(App),
}).$mount('#app')
