export const getRolesByEmail = async (resource, email) => {
  let result
  await resource
    .get(`api/user/find_by_email/` + email)
    .then(
      (response) => {
        result=response.body.user
      },
      (response) => {
        console.log(response);
      }
    );
  return result;
}

export const getRole = async (resource, id) => {
  let result
  await resource
    .get(`api/${localStorage.getItem('perfil')}/role/${id}`)
    .then(
      (response) => {
        result=response.body.role
      },
      (response) => {
        console.log(response);
      }
    );
  return result;
}