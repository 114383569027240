export const getSablishmentsByRole = async (resource, user_role_id) => {
  let result
  await resource
    .get(`api/user/find_by_role/` + user_role_id)
    .then(
      (response) => {
        result=response.body.user
      },
      (response) => {
        console.log(response);
      }
    );
  return result;
}

export const getStablishments = async (resource) => {
  let result;
  await resource.get(`api/${localStorage.getItem('perfil')}/stablishment`).then(
    (response) => {
      // success callback
      result = response.body.data;
    },
    (response) => {
      console.log(response);
      // error callback
    }
  );
  return result;
}

export const getStablishment = async (resource, id) => {
  let result;
  await resource.get(`api/${localStorage.getItem('perfil')}/stablishment/${id}`).then(
    (response) => {
      // success callback
      result = response.body.stablishment;
    },
    (response) => {
      console.log(response);
      // error callback
    }
  );
  return result;
}